import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Container, Row, Col, Media } from "react-bootstrap"
import lmsLogo from "../../images/lms-logo.png"
import machineStatus from "../../images/machine-status.svg"
import totalCapacity from "../../images/total-capacity.svg"
import alarmStatus from "../../images/alarms.svg"
import consumptionStatus from "../../images/consumption-status.png"
import currentprogramStatus from "../../images/current-program.svg"
import overall from "../../images/overall.svg"
import employeeProductivity from "../../images/employee-productivity.svg"
import rfidTag from "../../images/rfid-tag.svg"
import dowDoc from "../../images/download-doc.png"

const lms = () => (
  <Layout>
    <SEO title="Solutions"
     description="LMS is the professional laundry solution that lets you control your businesses end to end." />
    <Container>
      <div className='prolacs-divider-title' style={{ marginTop: '0', backgroundColor: '#FFFFFF', display: 'flex', alignItems: 'baseline' }}>
        <br></br>
        <br></br>
        <Link className='link-breadcrumb' to='/solutions'><h2 style={{ fontSize: '19px' }}>Solutions /</h2> </Link>
        <h1 style={{ fontSize: '20px', paddingLeft: '5px' }}> Laundry Management System </h1>
      </div>
      <Row style={{ marginBottom: '100px', marginTop: '100px' }}>
        <Col sm>
          <div className='solution-header'>
            <img src={lmsLogo} alt="Laundry Management System" />
          </div>
        </Col>
        <Col sm>
          <h1>Prolacs LMS</h1>
          <h2>Laundry Management System</h2>
          <p>LMS is the professional laundry solution that lets you control your businesses end to end. It has a modular and scalable structure suitable for every business. It increases the profitability of your business with modules such as consumption management, personel productivity and overal equipment efficiency.
          With machine learning and artificial intelligence modules, it makes anomaly detection and warns you before problems occur.
          Interface compatible with all smart devices, user-friendly interface is easy to use. You can easily use it with your internet browser from anywhere, without any installation.</p>
        </Col>
      </Row>

      <div className='prolacs-divider-title'>
        <br></br>
        <br></br>
        <h1>Specifications</h1>
      </div>
      <Row className='lms-inside-content'>
        <Col className='spesificationcol' col-sm>

          <h5>Real-Time Monitoring</h5>

          <p>Immediate analysis and controls allow early intervention in case of problems</p>
        </Col>
        <Col className='spesificationcol' col-sm>
          <h5>Compatible</h5>
          <p>Compatible with the world's leading products and brands</p>
        </Col>
        <Col className='spesificationcol' col-sm>
          <h5>Artificial Intelligence</h5>
          <p>LMS solutions are supported by artificial intelligence algorithms to help you optimize your operations</p>
        </Col>
      </Row>
      <Row className='lms-inside-content'>
        <Col className='spesificationcol' col-sm>
          <h5>Modular System</h5>
          <p>Get what your laundry needs with the LMS solution package</p>
        </Col>
        <Col className='spesificationcol' col-sm>
          <h5>Artificial Intelligence</h5>
          <p>Supported by A.I. algorithms that help in the optimization of laundries</p>
        </Col>
        <Col className='spesificationcol' col-sm>
          <h5>Performance</h5>
          <p>Advanced software infrastructure enables one million data display per second</p>
        </Col>
      </Row>



      <div className='prolacs-divider-title'>
        <br></br>
        <br></br>
        <h1>Features</h1>
      </div>
      <Row>
        <Col sm>
          <Media>
            <Media.Body>
              <h4>Machine Status</h4>
              <h5 style={{ fontSize: 'large' }}>Current status of each machine.</h5>
              <p>Running, cancel, pause, idling etc.</p>
            </Media.Body>
            <img
              className="ml-3"
              src={machineStatus}
              alt="Machine Status"
            />
          </Media>
        </Col>
        <Col sm>
          <Media>
            <Media.Body>
              <h4>Total Capacity Utilization</h4>
              <p>See total capacity utilization in real time.</p>
            </Media.Body>
            <img
              className="ml-3"
              src={totalCapacity}
              alt="Total Capacity Utilization"
            />
          </Media>
        </Col>
      </Row>
      <Row>
        <Col sm>
          <Media>
            <Media.Body>
              <h4>Alarms</h4>
              <h5 style={{ fontSize: 'large' }}>50+ alarms to alert when required.</h5>
              <p>Emergency Stop, Water Fill Fail, Inverter Alarm, Power Fail etc.</p>
            </Media.Body>
            <img
              className="ml-3"
              src={alarmStatus}
              alt="Alarms"
            />
          </Media>
        </Col>
        <Col sm>
          <Media>
            <Media.Body>
              <h4>Consumption Status</h4>
              <h5 style={{ fontSize: 'large' }}>Measures consumption such as energy, water, steam and chemicals.</h5>
              <p>Machine, Laundry or Production Line</p>
            </Media.Body>
            <img
              className="ml-3"
              src={consumptionStatus}
              alt="Consumption Status"
            />
          </Media>
        </Col>
      </Row>
      <Row>
        <Col sm>
          <Media>
            <Media.Body>
              <h4>Current Program on Machine</h4>
              <p>Allows you to see the current program running on machines in real-time.</p>
            </Media.Body>
            <img
              className="ml-3"
              src={currentprogramStatus}
              alt="Current Program on Machine"
            />
          </Media>
        </Col>
        <Col sm>
          <Media>
            <Media.Body>
              <h4>Overall Equipment Effectiveness (OEE)</h4>
              <p>Instead of unnecessary purchases, it allows to increase the performance of the machines and equipment available.</p>
            </Media.Body>
            <img
              className="ml-3"
              src={overall}
              alt="Overall Equipment Effectiveness (OEE)"
            />
          </Media>
        </Col>
      </Row>
      <Row>
        <Col sm>
          <Media>
            <Media.Body>
              <h4>Employee Productivity</h4>
              <p>Take control of work processes and reduce employee costs.</p>
            </Media.Body>
            <img
              className="ml-3"
              src={employeeProductivity}
              alt="Overall Equipment Effectiveness (OEE)"
            />
          </Media>
        </Col>
        <Col sm>
          <Media>
            <Media.Body>
              <h4>Compatible UHF Laundry RFID Tag</h4>
              <p>Full visibility over your textile assets in your laundry.</p>
            </Media.Body>
            <img
              className="ml-3"
              src={rfidTag}
              alt="Overall Equipment Effectiveness (OEE)"
            />
          </Media>
        </Col>
      </Row>
      <div className='prolacs-divider-title'>
        <br></br>
        <br></br>
        <h1>Modules</h1>
      </div>
      <Row>
        <Col sm>
          <div >
            <h4 className='controllerheadtext' style={{width:"100%"}}>Sensors & Control Systems</h4>
          </div>
          <p>Data from the PLC and sensors in the production line are collected and the required process controls are performed automatically.</p>
        </Col>
        <Col sm>
          <div >
            <h4 className='controllerheadtext' style={{width:"100%"}}>Data Collection Services</h4>
          </div>
          <p>Data collection services compatible with the world's leading PLC brands.</p>
        </Col>
      </Row>
      <Row>
        <Col sm>
          <div >
            <h4 className='controllerheadtext' style={{width:"100%"}}>Historical Data Storage Services</h4>
          </div>
          <p>Large-scale production data is stored historically and is quickly accessed when needed.</p>
        </Col>
        <Col sm>
          <div >
            <h4 className='controllerheadtext' style={{width:"100%"}}>Equipment Management and OEE</h4>
          </div>
          <p>It automatically measures and records production efficiency based on equipment and operator.</p>
        </Col>
      </Row>
      <Row>
        <Col sm>
          <div >
            <h4 className='controllerheadtext' style={{width:"100%"}}>Energy Management</h4>
          </div>
          <p>It controls the energy consumption which is one of the biggest expense.</p>
        </Col>
        <Col sm>
          <div >
            <h4 className='controllerheadtext' style={{width:"100%"}}>Consumption Management</h4>
          </div>
          <p>The consumption data of the work order are automatically recorded. It is analyzed for optimum cost.</p>
        </Col>
      </Row>
      <Row>
        <Col sm>
          <div >
            <h4 className='controllerheadtext' style={{width:"100%"}}>User Management</h4>
          </div>
          <p>Provides unlimited user support with group and user-based authorization.</p>
        </Col>
        <Col sm>
          <div >
            <h4 className='controllerheadtext' style={{width:"100%"}}>Work Order Management</h4>
          </div>
          <p>It enables the examination, optimization and editing of work orders from the ERP system.</p>
        </Col>
      </Row>
      <Row>
        <Col sm>
          <div >
            <h4 className='controllerheadtext' style={{width:"100%"}}>Job Order Planning</h4>
          </div>
          <p>It ensures optimum planning of work orders according to production plan.</p>
        </Col>
        <Col sm>
          <div >
            <h4 className='controllerheadtext' style={{width:"100%"}}>Notification Management</h4>
          </div>
          <p>Sends E-mail, SMS and Mobile App notifications according to defined set values.</p>
        </Col>
      </Row>
      <Row>
        <Col sm>
          <div >
            <h4 className='controllerheadtext' style={{width:"100%"}}>Statistical Process Control</h4>
          </div>
          <p>It enables analysis of the data collected from production by statistical process control algorithms.</p>
        </Col>
        <Col sm>
          <div>
            <h4 className='controllerheadtext' style={{width:"100%"}}>Process Analyses</h4>
          </div>
          <p>Millions of production data are analyzed by customized artificial intelligence algorithms.</p>
        </Col>
      </Row>
      <Row>
        <Col sm>
          <div >
            <h4 className='controllerheadtext' style={{width:"100%"}}>Recipe Management</h4>
          </div>
          <p>Data from the PLC and sensors in the production line are collected and the required process controls are performed automatically.</p>
        </Col>
        <Col sm>
          <div >
            <h4 className='controllerheadtext' style={{width:"100%"}}>Maintenance and Service Management</h4>
          </div>
          <p>It provides management of equipment breakdown, maintenance and parts changes.</p>
        </Col>
      </Row>
      <Row>
        <Col sm>
          <div >
            <h4 className='controllerheadtext' style={{width:"100%"}}>Task Management</h4>
          </div>
          <p>Assignment and follow-up of tasks on the basis of user or group.</p>
        </Col>
        <Col sm>
          <div >
            <h4 className='controllerheadtext' style={{width:"100%"}}>Real-Time Monitoring</h4>
          </div>
          <p>Recorded millions of production data are displayed in seconds with algorithms in graphical or tabular form.</p>
        </Col>
      </Row>
      <Row>
        <Col sm>
          <div >
            <h4 className='controllerheadtext' style={{width:"100%"}}>Reporting Services</h4>
          </div>
          <p>Provides customized and flexible reports.</p>
        </Col>
        <Col sm>
          <div >
            <h4 className='controllerheadtext' style={{width:"100%"}}>Data Analysis</h4>
          </div>
          <p>It enables the analysis of the relationship between the collected data.</p>
        </Col>
      </Row>
      <Row>
        <Col sm>
          <div >
            <h4 className='controllerheadtext' style={{width:"100%"}}>Mobile Application</h4>
          </div>
          <p>Retmes provides access from anywhere with Android and iOS-developed applications.</p>
        </Col>
        <Col sm>
          <div >
            <h4 className='controllerheadtext' style={{width:"100%"}}>Web Applications</h4>
          </div>
          <p>It is accessible from all platforms based on Windows, Linux, MacOS, iOS and Android.</p>
        </Col>
      </Row>
      <Row>
        <Col sm>
          <div >
            <h4 className='controllerheadtext' style={{width:"100%"}}>Rest API</h4>
          </div>
          <p>Prolacs's Rest APIs provide flexible, simple and fast communication over the HTTP protocol.</p>
        </Col>
        <Col sm>
          <div >
            <h4 className='controllerheadtext' style={{width:"100%"}}>Quality management</h4>
          </div>
          <p>Data from the quality management system is used as input in the process optimization cycle to improve production quality.</p>
        </Col>
      </Row>
      <Row>
        <Col sm>
          <div >
            <h4 className='controllerheadtext' style={{width:"100%"}}>Process Optimization</h4>
          </div>
          <p>Data from machine learning are used for process optimization.</p>
        </Col>
        <Col sm>
          <div >
            <h4 className='controllerheadtext' style={{width:"100%"}}>Machine Learningn</h4>
          </div>
          <p>Using the data of millions of production, the most accurate estimation is made by using customized artificial intelligence algorithms.</p>
        </Col>
      </Row>
      <br />
      <br />
      <br />
      <Row>
        <Col className='product-detail-doc-name' style={{ textAlign: 'center' }}>
          <img className='product-detail-doc' src={dowDoc} alt=" download full smart laundromat control doc" />
          <h1>Download the full paper</h1>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default lms
